import React, { useState } from 'react';
import WorkListTable from './WorkListTable';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import theme from '../theme';
import AddIcon from '@mui/icons-material/Add'; // İkonu import ediyoruz


function TaskListPageTab() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    // İş ekleme işlemleri burada gerçekleştirilecek
    console.log('Kaydetme işlemi gerçekleştirilecek');
    setOpen(false);
  };

  return (
    <div style={{ width: '75%', margin: '0 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
        <InputLabel sx={{
          fontWeight: 'bold',
          fontSize: '0.9rem',
          color: theme.palette.primary.main,
        }}>
          İş Listesi Sorumlusu: Ersan Çetin
        </InputLabel>

        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          disableRipple // Tıklama efektini kaldır
          startIcon={<AddIcon />}
          sx={{
            minHeight: '36px', // Butonun minimum yüksekliği
            height: '20px', // Özel yükseklik
            fontSize: '0.75rem', // Özel font boyutu
            // Diğer özelleştirmeleri buraya ekleyebilirsiniz
            
          }} 
        >
          İş Ekle
        </Button>
      </div>

      <WorkListTable />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Yeni İş Ekle</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="client"
            label="Müvekkil"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="caseName"
            label="İş Adı"
            type="text"
            fullWidth
            variant="standard"
          />
          {/* Diğer gerekli TextField bileşenleri de benzer şekilde eklenecek */}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            İptal
          </Button>
          <Button onClick={handleSave} color="primary">
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TaskListPageTab;
