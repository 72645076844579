// HomePageTab.js

import React from 'react';

const FilesPageTab = () => {
    return (
        <div>
            Dosyalar içeriği burada.
        </div>
    );
};

export default FilesPageTab;
