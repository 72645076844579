import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu'; // Menu bileşenini içe aktarın
import MenuItem from '@mui/material/MenuItem'; // MenuItem bileşenini içe aktarın
import HomePageTab from './HomePageTab';
import FilesPageTab from './FilesPageTab';
import TaskListPageTab from './TaskListPageTab'; // Eğer aynı dizindeyse


// Emotion ile özel stil uygulanmış Tabs bileşeni
const StyledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: 'white',
    },
    '& .MuiTabs-flexContainer': {
        position: 'relative',
    },
    '& .MuiTouchRipple-root': {
        borderRadius: '16px', // Ripple efektine borderRadius ekleyin
    },
});

// Emotion ile özel stil uygulanmış Tab bileşeni
const StyledTab = styled(Tab)({
    textTransform: 'none',
    color: 'white',
    fontWeight: '400', // İnce font ağırlığı
    '&:hover': {
        color: 'white',
        opacity: 1,
        // Hover efekti için geçici bir alt çizgi ekleyin
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '2px',
            backgroundColor: 'white',
            transform: 'scaleX(0)',
            transition: 'transform 0.3s ease-in-out',
        },
    },
    '&.Mui-selected': {
        color: 'white',
        '&::after': {
            transform: 'scaleX(1)', // Seçili ve hover durumunda çizgiyi genişlet
        },
        fontWeight: '500', // Seçili tab için biraz daha kalın font

    },
    '&:focus': {
        color: 'white',
    },
});


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
    display: 'flex', // Bu, içeriği flex kutusu yapar
    alignItems: 'center', // Bu, içeriği dikey olarak ortalar
    justifyContent: 'left', // Bu, içeriği yatay olarak sola hizalar
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //height: '100%', // İkonun kutunun içinde tam ortada olmasını sağlar
    //alignItems: 'center', // Bu, ikonu dikey olarak ortalar
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1), // Üst ve alt padding değerlerini ayarlayın
        paddingLeft: `calc(1em + ${theme.spacing(4)})`, // Yatay padding ve arama ikonu için sol padding
        transition: theme.transitions.create('width'),
        width: '100%',
        height: '100%', // Input alanının yüksekliğini ayarlayın
        lineHeight: 'inherit', // Satır yüksekliğini ayarlayın
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function PrimarySearchAppBar() {

    // Seçili tab'ı takip etmek için bir durum değişkeni
    const [selectedTab, setSelectedTab] = React.useState(0);

    // Tab değiştiğinde çağrılacak işlev
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="error">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ marginRight: '5%' }}>
                        Juris Ops
                    </Typography>
                    <StyledTabs value={selectedTab} onChange={handleTabChange} sx={{ marginRight: 'auto' }}>
                        <StyledTab label="Ana Sayfa" />
                        <StyledTab label="Dosyalar" />
                        <StyledTab label="İş Listesi" />
                    </StyledTabs>
                    <Box sx={{ display: 'flex', flexGrow: 0 }}>
                        {/* Arama butonu ve inputu */}
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Arama..."
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="error">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <Badge badgeContent={17} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {/* Tab içeriği */}
            <Box component="main" sx={{ pt: 8, flexGrow: 1 }}> {/* AppBar altında içerik */}
            {selectedTab === 0 && <TaskListPageTab />}
            {selectedTab === 1 && <TaskListPageTab />}
            {selectedTab === 2 && <TaskListPageTab />}
            </Box>
            {renderMobileMenu}
            {renderMenu}
        </Box>
    );
}
