import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Divider, Link } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(''); // Şifre için state eklendi
  const navigate = useNavigate();

  const handleChange = (e) => {
    // Input adına göre state güncelleniyor
    if (e.target.name === 'email') {
      setEmail(e.target.value);
    } else if (e.target.name === 'password') {
      setPassword(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password); // Email ve şifre kullanılarak giriş yapılıyor
      console.log('Giriş yapıldı:', userCredential.user);
      navigate('/dashboard'); // Kullanıcıyı anasayfaya yönlendir
    } catch (error) {
      console.error('Giriş hatası:', error);
    }
  };

  const handleSignUpClick = () => {
    navigate('/signup'); // Kayıt sayfasına yönlendir
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h1" sx={{ marginBottom: 4 }}> 
          Hoş geldiniz!
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: 400, margin: 'auto' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Mail adresiniz"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Şifre"
            name="password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={handleChange}
            sx={{ mt: 2 }} // Şifre alanının üstündeki boşluğu ayarlar
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Devam
          </Button>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 3, textAlign: 'center' }}>
            Hesabınız yok mu?{' '}
            <Link
              component="button"
              variant="body2"
              onClick={handleSignUpClick}
              sx={{ color: 'success.main' }}
            >
              Kayıt olun
            </Link>
          </Typography>
          <Divider sx={{ my: 2, position: 'relative', '&::before, &::after': { top: '50%' } }}>
          <Typography 
            sx={{ 
              position: 'absolute', 
              top: '-0.5em', 
              left: '50%', 
              transform: 'translateX(-50%)', 
              bgcolor: 'background.default', 
              px: 1,
              fontFamily: 'Roboto', // Roboto fontu
              fontWeight: 100,
            }}
          >
            Veya
          </Typography>
        </Divider>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<GoogleIcon />}
            sx={{ mt: 1, mb: 2 }}
          >
            Google ile oturum aç
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default LoginPage;
