// firebase.js
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDAZzVLK5YnAtHKbCI_-V1r39ZiuBP3slo",
  authDomain: "juris-ops.firebaseapp.com",
  databaseURL: "https://juris-ops-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "juris-ops",
  storageBucket: "juris-ops.appspot.com",
  messagingSenderId: "970491191103",
  appId: "1:970491191103:web:6cf30d2d5a15b4750b7d8a",
  measurementId: "G-CEYJRPVX8J"
};
// Firebase uygulamasını başlat
const app = initializeApp(firebaseConfig);

// Firebase auth nesnesini al

export default app;

