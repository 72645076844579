import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import Dashboard from './pages/Dashboard';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import './App.css'; // kullandığınız CSS dosyasının yolu

const ThemedApp = () => {
  // Ana sayfada doğrudan /login sayfasına yönlendirme yapmak için aşağıdaki gibi bir şey kullanabilirsiniz.
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          {/* Diğer rotalarınız burada olabilir */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default ThemedApp;
