import React from 'react';
import { DataGrid, trTR } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import Chip from '@mui/material/Chip';


const columns = [
    { field: 'client', headerName: 'Müvekkil', flex: 0.5 },
    {
        field: 'caseName',
        headerName: 'İş Adı',
        flex: 0.5,
        renderCell: (params) => (
            <Chip
            label={params.value}
            size="small" // Chip boyutunu küçült
            sx={{
              fontSize: '0.75rem', // Yazı boyutunu küçült
              // Diğer stiller...
            }}
          />
            ),
    },
    { field: 'mahkemeBilgisi', headerName: 'Mahkeme Bilgisi', flex: 0.5 },
    { field: 'fileNumber', headerName: 'Esas No', flex: 0.5 },
    {
        field: 'caseInfo',
        headerName: 'İşin Konusu',
        flex: 0.75
    },
    {
        field: 'assignmentDate',
        headerName: 'Tebliğ Tarihi',
        flex: 0.5,
        valueFormatter: (params) => {
            return formatDate(params.value); // Tarih formatlama fonksiyonu
        },
    },
    {
        field: 'dueDate',
        headerName: 'Son Tarih',
        flex: 0.5,
        valueFormatter: (params) => {
            return formatDate(params.value); // Tarih formatlama fonksiyonu
        },
    },
    {
        field: 'actions',
        headerName: 'İşlemler',
        flex: 0.5,
        align: 'center',
        renderCell: (params) => (
            <div>
                <IconButton
                    aria-label="folder"
                    sx={{
                        padding: '5px', // Padding değerini azaltarak buton boyutunu küçült
                        color: '#757575',
                        '& .MuiSvgIcon-root': { // Iconun kendisinin boyutunu ayarla
                            fontSize: '1.2rem', // Buradaki değeri istediğiniz boyuta göre ayarlayın
                        },
                        '&:hover': { // Mouse üzerine gelince
                            color: '#556cd6', // Mouse üzerine gelince icon rengi
                            backgroundColor: '#backgroundColor', // İsteğe bağlı olarak arka plan rengi de ekleyebilirsiniz
                            // Örneğin tema renklerini kullanmak için theme.palette.primary.main gibi bir değer kullanabilirsiniz.
                        }
                    }}
                >
                    <FolderOpenOutlinedIcon />
                </IconButton>

                <IconButton
                    aria-label="arrow"
                    sx={{
                        padding: '5px', // Padding değerini azaltarak buton boyutunu küçült
                        color: '#757575',
                        '& .MuiSvgIcon-root': { // Iconun kendisinin boyutunu ayarla
                            fontSize: '1.2rem', // Buradaki değeri istediğiniz boyuta göre ayarlayın
                        },
                        '&:hover': { // Mouse üzerine gelince
                            color: '#556cd6', // Mouse üzerine gelince icon rengi
                            backgroundColor: '#backgroundColor', // İsteğe bağlı olarak arka plan rengi de ekleyebilirsiniz
                            // Örneğin tema renklerini kullanmak için theme.palette.primary.main gibi bir değer kullanabilirsiniz.
                        }
                    }}
                >
                    <ArrowCircleRightOutlinedIcon />
                </IconButton>

                <IconButton
                    aria-label="done"
                    sx={{
                        padding: '5px', // Padding değerini azaltarak buton boyutunu küçült
                        color: '#757575',
                        '& .MuiSvgIcon-root': { // Iconun kendisinin boyutunu ayarla
                            fontSize: '1.2rem', // Buradaki değeri istediğiniz boyuta göre ayarlayın
                        },
                        '&:hover': { // Mouse üzerine gelince
                            color: '#556cd6', // Mouse üzerine gelince icon rengi
                            backgroundColor: '#backgroundColor', // İsteğe bağlı olarak arka plan rengi de ekleyebilirsiniz
                            // Örneğin tema renklerini kullanmak için theme.palette.primary.main gibi bir değer kullanabilirsiniz.
                        }
                    }}
                >
                    <CheckCircleOutlineOutlinedIcon />

                </IconButton>

                <IconButton
                    aria-label="info"
                    sx={{
                        padding: '5px', // Padding değerini azaltarak buton boyutunu küçült
                        color: '#757575',
                        '& .MuiSvgIcon-root': { // Iconun kendisinin boyutunu ayarla
                            fontSize: '1.2rem', // Buradaki değeri istediğiniz boyuta göre ayarlayın
                        },
                        '&:hover': { // Mouse üzerine gelince
                            color: '#556cd6', // Mouse üzerine gelince icon rengi
                            backgroundColor: '#backgroundColor', // İsteğe bağlı olarak arka plan rengi de ekleyebilirsiniz
                            // Örneğin tema renklerini kullanmak için theme.palette.primary.main gibi bir değer kullanabilirsiniz.
                        }
                    }}
                >
                    <InfoOutlinedIcon />
                </IconButton>
            </div>
        ),
    },
];

function formatDate(value) {
    if (!value) return '';
    const date = new Date(value);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

const rows = [
    { id: 1, client: 'Müvekkil A', caseName: 'UETS', mahkemeBilgisi: 'Ankara 1. Asliye Ticaret Mahkemesi', fileNumber: '2023/01', caseInfo: 'Ticari Dava', assignmentDate: '2023-03-01', dueDate: '2023-09-01' },
    { id: 2, client: 'Müvekkil B', caseName: 'KEP', mahkemeBilgisi: 'İstanbul 3. İş Mahkemesi', fileNumber: '2023/02', caseInfo: 'İş Hukuku Davası', assignmentDate: '2023-04-15', dueDate: '2023-10-15' },
    { id: 3, client: 'Müvekkil C', caseName: 'Mail', mahkemeBilgisi: 'İzmir 2. Fikri ve Sınai Haklar Mahkemesi', fileNumber: '2023/03', caseInfo: 'Fikri Mülkiyet Davası', assignmentDate: '2023-05-20', dueDate: '2023-11-20' },
    { id: 4, client: 'Müvekkil D', caseName: 'Bilirkişi Raporu', mahkemeBilgisi: 'Bursa 1. Sulh Hukuk Mahkemesi', fileNumber: '2023/04', caseInfo: 'Kira Anlaşmazlığı', assignmentDate: '2023-02-10', dueDate: '2023-08-10' },
    { id: 5, client: 'Müvekkil E', caseName: 'Cevap Dilekçesi', mahkemeBilgisi: 'Antalya 3. Asliye Hukuk Mahkemesi', fileNumber: '2023/05', caseInfo: 'Tazminat Davası', assignmentDate: '2023-06-05', dueDate: '2023-12-05' },
    { id: 6, client: 'Müvekkil A', caseName: 'UETS', mahkemeBilgisi: 'Ankara 1. Asliye Ticaret Mahkemesi', fileNumber: '2023/01', caseInfo: 'Ticari Dava', assignmentDate: '2023-03-01', dueDate: '2023-09-01' },
    { id: 7, client: 'Müvekkil B', caseName: 'KEP', mahkemeBilgisi: 'İstanbul 3. İş Mahkemesi', fileNumber: '2023/02', caseInfo: 'İş Hukuku Davası', assignmentDate: '2023-04-15', dueDate: '2023-10-15' },
    { id: 8, client: 'Müvekkil C', caseName: 'Mail', mahkemeBilgisi: 'İzmir 2. Fikri ve Sınai Haklar Mahkemesi', fileNumber: '2023/03', caseInfo: 'Fikri Mülkiyet Davası', assignmentDate: '2023-05-20', dueDate: '2023-11-20' },
    { id: 9, client: 'Müvekkil D', caseName: 'Bilirkişi Raporu', mahkemeBilgisi: 'Bursa 1. Sulh Hukuk Mahkemesi', fileNumber: '2023/04', caseInfo: 'Kira Anlaşmazlığı', assignmentDate: '2023-02-10', dueDate: '2023-08-10' },
    { id: 10, client: 'Müvekkil E', caseName: 'Cevap Dilekçesi', mahkemeBilgisi: 'Antalya 3. Asliye Hukuk Mahkemesi', fileNumber: '2023/05', caseInfo: 'Tazminat Davası', assignmentDate: '2023-06-05', dueDate: '2023-12-05' },
    { id: 11, client: 'Müvekkil B', caseName: 'KEP', mahkemeBilgisi: 'İstanbul 3. İş Mahkemesi', fileNumber: '2023/02', caseInfo: 'İş Hukuku Davası', assignmentDate: '2023-04-15', dueDate: '2023-10-15' },
    { id: 12, client: 'Müvekkil C', caseName: 'Mail', mahkemeBilgisi: 'İzmir 2. Fikri ve Sınai Haklar Mahkemesi', fileNumber: '2023/03', caseInfo: 'Fikri Mülkiyet Davası', assignmentDate: '2023-05-20', dueDate: '2023-11-20' },
    { id: 13, client: 'Müvekkil D', caseName: 'Bilirkişi Raporu', mahkemeBilgisi: 'Bursa 1. Sulh Hukuk Mahkemesi', fileNumber: '2023/04', caseInfo: 'Kira Anlaşmazlığı', assignmentDate: '2023-02-10', dueDate: '2023-08-10' },
    { id: 14, client: 'Müvekkil E', caseName: 'Cevap Dilekçesi', mahkemeBilgisi: 'Antalya 3. Asliye Hukuk Mahkemesi', fileNumber: '2023/05', caseInfo: 'Tazminat Davası', assignmentDate: '2023-06-05', dueDate: '2023-12-05' },
    // ... diğer satırlar
  ];
  

function CustomTable() {

    return (
        <ThemeProvider theme={theme}> {/* Tema sağlayıcı */}
            <div style={{ width: '100%', margin: '0 auto' }}> {/* Genişlik %80, otomatik yan marjlar */}
                <DataGrid
                    rows={rows}
                    columns={columns}
                    columnHeaderHeight={50} // Sütun başlıklarının yüksekliğini burada ayarlayın
                    localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 25]}
                    checkboxSelection={false}
                    hideFooterSelectedRowCount={true} // Seçili satır sayısını gizle
                    rowHeight={40} // Satır yüksekliğini burada ayarlayın
                />
            </div>
        </ThemeProvider>
    );
}

export default CustomTable;