import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Alert, FormControlLabel, Checkbox, Link } from '@mui/material';

function SignUpPage() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    termsAccepted: false, // Kullanım Şartlarını kabul etme durumu için yeni state
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: name === 'termsAccepted' ? e.target.checked : value });
    setError(''); // Her değişiklikte hata mesajını temizle
  };

  const validatePassword = (password) => {
    if (password.length < 8) {
      setError("Şifre en az 8 karakter olmalıdır.");
      return false;
    }
    // Daha fazla şifre gereksinimi buraya eklenebilir
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, confirmPassword } = formData;

    // Form alanlarının boş olup olmadığını kontrol et
    if (!email || !password || !confirmPassword) {
      setError("Lütfen tüm alanları doldurunuz.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Şifreler uyuşmuyor.");
      return;
    }
    if (!validatePassword(password)) {
      return;
    }

    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('Yeni kullanıcı oluşturuldu:', userCredential.user);
      navigate('/login'); // Kullanıcı oluşturulduktan sonra yönlendirme
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('Bu e-posta adresiyle zaten bir hesap var.');
      } else {
        setError('Kayıt sırasında bir hata oluştu.'); // Genel hata mesajı
      }
    }
  };


  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h1" sx={{ mb: 4 }}>
          Kayıt ol
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%', margin: 'auto' }}>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Mail adresiniz"
            name="email"
            autoComplete="email"
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Şifre"
            name="password"
            type="password"
            autoComplete="new-password"
            value={formData.password}
            onChange={handleChange}
            sx={{ mt: 2 }} // Şifre alanı için üst boşluk
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            label="Şifreyi Onayla"
            name="confirmPassword"
            type="password"
            autoComplete="new-password"
            value={formData.confirmPassword}
            onChange={handleChange}
            sx={{ mt: 2 }} // Şifreyi onayla alanı için üst boşluk
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.termsAccepted}
                onChange={handleChange}
                name="termsAccepted"
                color="primary"
              />
            }
            label={
              <Typography>
                <Link href="#" onClick={(e) => e.preventDefault()} underline="always">
                  Kullanım Şartlarını
                </Link>
                {' okudum onaylıyorum.'}
              </Typography>
            }
            sx={{ marginY: 2 }} // Yukarı ve aşağıya 16px boşluk ekler
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: 'primary.main',
              color: 'white',
              '&.Mui-disabled': {
                bgcolor: 'primary.main', // Devre dışıyken de aynı arka plan rengini kullan
                color: 'white',
                opacity: 0.5 // Devre dışı olduğunu belirtmek için hafif bir opaklık
              }
            }}
            disabled={!formData.termsAccepted} // Kullanım Şartları onaylanmadıysa butonu devre dışı bırak
          >
            Kayıt Ol
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default SignUpPage;
