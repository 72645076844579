import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#ff5252',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 400,
      fontSize: '2.125rem', // Örneğin, 34px
      textTransform: 'none',
      marginBottom: '1rem',
    },
    h2: {
      fontWeight: 500,
      fontSize: '1.5rem', // Örneğin, 24px
      textTransform: 'none',
      marginBottom: '0.75rem',
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.25rem', // Örneğin, 20px
      marginBottom: '0.5rem',
    },
  },
  shape: {
    borderRadius: 9,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: '56px',
          fontSize: '0.875rem',
          marginBottom: '2px',
          borderRadius: '9px',
          textTransform: 'none', // Butonlarda büyük harf kullanımını devre dışı bırakır
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            minHeight: '24px',
            fontSize: '1rem',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '16px', // TextField kenar yuvarlaklığını burada ayarlayabilirsiniz
          },
          marginBottom: '2px',
        },
      },
    },
    // Diğer bileşenlerin stil özelleştirmeleri...
    MuiInputLabel: {
      fontWeight: 'bold',
      fontSize: '16px',
    },// Diğer etiket stilleri buraya eklenebilir

    MuiDataGrid: {
      styleOverrides: {
        root: {
          minHeight: '300px',

          '& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: 'rgba(85, 108, 214, 0.2)', // Tema renginin açık tonu
          },
          '& .MuiDataGrid-cell': {
            fontSize: '12px', // Font boyutunu burada ayarla
            whiteSpace: 'nowrap', // İçeriği tek satırda tut
            overflow: 'hidden', // Fazla içeriği gizle
            textOverflow: 'ellipsis', // Kesilen içeriği "..." ile göster
            paddingLeft: '16px', // Hücrelere soldan padding
            paddingRight: '24px', // Hücrelere soldan padding

          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#7587d1', // Yumuşak gri ton
            color: '#f4f4f4', // Koyu gri metin rengi, okunabilirlik için
        },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '12px', // Font boyutunu burada ayarla
            paddingLeft: '7px', // Hücrelere soldan padding

          },

        },
      },
    },
  },

});

export default theme;
