import React from 'react';
import PrimarySearchAppBar from '../components/AppBar'; // AppBar bileşenini içe aktarın

function App() {
  return (
    <div className="App">
      <PrimarySearchAppBar />
    </div>
  );
}

export default App;
